import React, { useEffect, useState, useContext } from "react";
import SEO from "../components/SEO";
import HeroSection from "@src/components/partials/schoolleader/HeroSection";
import CommunicationSection from "@src/components/partials/schoolleader/CommunicationSection";
import TestimonialSection from "@src/components/partials/schoolleader/TestimonialSection";
import FactSection from "@src/components/partials/schoolleader/FactSection";
import GetStarted from "@src/components/partials/schoolleader/GetStarted";
import Testimonials from "@src/components/partials/schoolleader/Testimonials";
import Privacy from "@src/components/partials/schoolleader/Privacy";
import FAQs from "@src/components/partials/schoolleader/FAQs";
import StickyCarousel from "@src/components/partials/schoolleader/StickyCarousel";
import CalendlyModal from "@src/components/CalendlyModal";
import { logEvent } from "@src/utils/logClient";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const Schools = () => {
  const data = useStaticQuery(graphql`
    query {
      heroIllustration: file(relativePath: { eq: "schoolleader/hero-illustration@2x.png" }) {
        publicURL
      }
      directus {
        page_schoolleader_2024 {
          Youtube_video_id
          FAQs
          carousel_content
          communication_animation {
            filename_disk
          }
          get_started_cards
          testimonial_author
          testimonial_headshot {
            filename_disk
          }
          testimonial_fact_image {
            filename_disk
          }
          privacy_logos {
            directus_files_id {
              filename_disk
            }
          }
        }
        page_schoolleader_2024_testimonials {
          testimonials_id {
            author
            id
            location
            quote
            thumb {
              filename_disk
            }
          }
        }
        common_data_schools {
          traits
          features
          feedbacks
          faq_items
          classdojo_help_link_url
          cta_background_image_url
        }
        page_schools {
          hero_button_url
          hero_video_image {
            id
            filename_disk
          }
          hero_video_url
          balloons_badge_image {
            id
            filename_download
            filename_disk
          }
          star_badge_icon {
            id
            filename_download
            filename_disk
          }
          star_badge_alt
          resources_image {
            id
            filename_download
            filename_disk
          }
          resources_button_url
          resources_webinar_button_url
          resources_wave_top_image {
            id
            filename_download
            filename_disk
          }
          resources_wave_bottom_image {
            id
            filename_download
            filename_disk
          }
          perks
          perks_check_icon {
            id
            filename_download
            filename_disk
          }
          perks_check_alt
          cta_button_url
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    directus: {
      common_data_schools: {
        features,
        feedbacks,
        traits,
        faq_items,
        classdojo_help_link_url,
        cta_background_image_url,
      },
      page_schools: {
        hero_video_image,
        hero_video_url,
        hero_button_url,
        star_badge_icon,
        balloons_badge_image,
        resources_image,
        resources_webinar_button_url,
        resources_button_url,
        perks,
        perks_check_icon,
        cta_button_url,
      },
    },
  } = data;

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  useEffect(() => {
    logEvent({
      eventName: "teacher.naesp.load_page",
      eventValue: location.href,
      metadata: params,
    });
  }, []);

  const t = useContext(TranslationContext);

  function createTranslatedFaqArray(faqs) {
    const array = {
      title: t.translate("directus.page_schoolleader_2024.FAQs_title"),
      faq: faqs.map((_, index) => ({
        question: t.translate(`directus.page_schoolleader_2024.FAQs.question_${index + 1}`),
        answer: t.translate(`directus.page_schoolleader_2024.FAQs.answer_${index + 1}`),
      })),
    };

    return array;
  }

  const faqsFromQuery = data.directus.page_schoolleader_2024.FAQs;
  const translatedFaqArray = createTranslatedFaqArray(faqsFromQuery);

  const translatedCarouselContent = data.directus.page_schoolleader_2024.carousel_content.map((content, index) => ({
    imageSrc: content.image,
    mobileSrc: content.mobile_image,
    heading: t.translate(`directus.page_schoolleader_2024.carousel_content.heading_${index + 1}`),
    paragraph: t.translate(`directus.page_schoolleader_2024.carousel_content.paragraph_${index + 1}`),
    detail_text: t.translate(`directus.page_schoolleader_2024.carousel_content.detail_text_${index + 1}`),
  }));

  const [showCalendly, setShowCalendly] = useState(false);
  const [calendlyEntryPoint, setCalendlyEntryPoint] = useState("");

  const openCalendly = (string) => {
    setCalendlyEntryPoint(string);
    setShowCalendly(true);
    logEvent({
      eventName: "web.external.naesp.open_calendly_captcha",
      eventValue: window.location.href,
    });
  };

  const closeCalendly = () => {
    setShowCalendly(false);
  };

  return (
    <>
      <SEO
        title="directus.page_schoolleader_2024.SEO_title"
        description="directus.page_schoolleader_2024.SEO_description"
        image="https://static.classdojo.com/img/schoolleader/schoolleader-share.png"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: t.translate("directus.page_schoolleader_2024.SEO_title"),
          description: t.translate("directus.page_schoolleader_2024.SEO_description"),
          image: "https://static.classdojo.com/img/schoolleader/schoolleader-share.png",
        }}
        noindex={true}
      />
      <HeroSection
        videoId={data.directus.page_schoolleader_2024.Youtube_video_id}
        openCalendly={openCalendly}
        showForm={true}
      />
      <StickyCarousel content={translatedCarouselContent} openCalendly={openCalendly} />
      <CommunicationSection illustration={data.directus.page_schoolleader_2024.communication_animation.filename_disk} />
      <TestimonialSection
        headshot={data.directus.page_schoolleader_2024.testimonial_headshot.filename_disk}
        author={data.directus.page_schoolleader_2024.testimonial_author}
      />
      <FactSection illustration={data.directus.page_schoolleader_2024.testimonial_fact_image.filename_disk} />
      <GetStarted cards={data.directus.page_schoolleader_2024.get_started_cards} />
      <Testimonials cards={data.directus.page_schoolleader_2024_testimonials} />
      <Privacy logos={data.directus.page_schoolleader_2024.privacy_logos} />
      <FAQs {...translatedFaqArray} />
      {showCalendly && (
        <CalendlyModal
          logEventContext={`naesp_${calendlyEntryPoint}`}
          calendlyLink={`https://calendly.com/d/449-j2w-t3m?utm_campaign=${calendlyEntryPoint}`}
          closeModal={closeCalendly}
        />
      )}
    </>
  );
};

export default Schools;
